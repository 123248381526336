.footerMain {
  padding: 5% 0;
  border-top: solid grey 1px;
  height: 341px;
  background-color: #181716;
  width: 100vw;
  font-family: Circularstd-Book;
  font-weight: 350;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 1033px;
  display: flex;
  justify-content: center;
}

.footerChild {
  width: 25%;
  display: flex;
  flex-direction: column;
  padding: 2%;
}

.footerChild h4 {
  color: #fffff2;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 350;
}

.footerChild h5 {
  color: #928e8c;
  font-size: 14px;
  font-weight: 350;
  line-height: 130%;
}

@media only screen and (max-width: 768px) {
  .footerMain {
    padding: 3%;
    border-top: solid grey 1px;
    display: flex;
    width: 100%;
  }

  .wrapper {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .footerChild {
    width: 50%;
  }

  .footerChild:nth-of-type(1) {
    display: none;
  }
}
