.main {
  background-color: #fffffa;
  overflow-x: hidden;
}

.tilesImage {
  background-image: url("https://global-uploads.webflow.com/5ddd2caed93ad8355b40f958/62656a7930a5c2483feac929_grid%20pattern.png");
  background-size: 28px;

  position: relative;
}

.hamburger {
  display: none;
}

.mobileLogoParent {
  display: none;
}

.nav {
  display: flex;
  justify-content: center;
  padding: 15px 150px;
  color: #fffff2;
  width: 100%;
}

.navParent {
  display: flex;
  align-items: center;
  width: 1033px;
}

.logoParent {
  margin-right: 50px;
}

.navRightParent {
  display: flex;
  margin-left: auto;
}

.navChild {
  margin-left: 20px;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  font-size: 14px;
  height: 40px;
}

.firstNav {
  border-bottom: 3px solid #fffff2;
  padding-bottom: 15px;
}

.top {
  color: #fffff2;
  height: 840px;
  overflow: visible;
  width: 100%;
  margin-top: 80px;
}

.topDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  text-align: center;
  font-size: 80px;
  font-weight: 450;
  letter-spacing: -2.4px;
}

.titleMobile {
  display: none;
}

.subtitle {
  color: var(--4---Beige, #e6e1df);
  text-align: center;
  font-size: 32px;
  font-weight: 450;

  margin-top: 26px;

  max-width: 1047px;

  align-self: center;
}

.downloadButtonsDiv {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  gap: 15px;

  margin-top: 58px;

  z-index: 4;
}

.downloadButtonsDivMobile {
  display: none;
}

.topPhoneImage {
  width: 840.952px;
  height: 672.964px;

  position: absolute;
  bottom: -217px;
  left: 50%;
  transform: translateX(-50%);

  z-index: 2;
}

.mid {
  background-image: url("https://global-uploads.webflow.com/5ddd2caed93ad8355b40f958/626571ea732d388b092aadbe_huge-dimensional-log.png");
  background-position: 50% 48%;
  background-size: 90vw;
  background-repeat: no-repeat;
  height: 1040px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 130px 150px; */
  background-color: #fffffa;

  position: relative;
}

.midParent {
  display: flex;
  justify-content: center;
  gap: 120px;
  width: 1033px;

  margin-top: 300px;
}

.midLeft {
  display: flex;
  flex-direction: column;
  width: 529px;

  gap: 30px;
}

.midLeftTitle {
  color: #2d2927;
  font-size: 60px;
  font-style: normal;
  font-weight: 450;
  line-height: 110%;
  letter-spacing: -1.8px;
}

.midLeftSubtitle {
  color: #2d2927;
  font-size: 26px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.78px;

  opacity: 0.5;
}

.midRight {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 30px;

  max-width: 522px;
}

.dimensionBlock {
  width: 77.994px;
  height: 107.632px;

  overflow: visible;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 7.8px;
}

.dimensionIconDiv {
  width: 77.994px;
  height: 77.994px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;
}

.dimensionBlockTitle {
  color: var(--1---Dark-brown, #2d2927);
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.39px;
}

.midBottom {
  width: 1600px;
  max-width: 1600px;
  height: 228px;

  padding: 0 10px;

  position: absolute;
  bottom: 85px;
  left: 50%;
  transform: translateX(-50%);
}

.midBottomImage {
  height: auto !important;
}

.freakishSection {
  background: #2d2927;

  position: relative;

  padding-top: 135px;
  padding-bottom: 62px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.freakishBackgroundImage {
  width: calc(100% - 160px);
  max-width: 1464.381px;
  height: 429px;
  max-height: 429px;

  position: absolute;

  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
}

.freakishTitle {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 60px;
  font-weight: 450;
  line-height: 110%;
  letter-spacing: -1.8px;
}

.freakishSubTitle {
  color: #fffff2;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.78px;

  margin-top: 20px;

  opacity: 0.5;

  padding: 0 5px;
}

.freakishImage {
  position: relative;

  width: 961px;
  height: 673px;

  margin-top: 75px;
}

.freakishImageMobile {
  display: none;
}

.connectSection {
  background: #fffffa;
  position: relative;
  height: 1001px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: url("/HomeConnectBgImage.png");
  background-size: cover;
  background-position: center;
}

.connectWrapper {
  margin-top: 149px;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 160px;
}

.connectLeft {
  display: flex;
  flex-direction: column;
  width: 565px;

  gap: 30px;
}

.connectRight {
  width: 325px;
  max-width: 325px;
}

.circleDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;
}

.circleActive {
  width: 16px;
  height: 16px;

  border-radius: 50%;

  background-color: #55504f;
}

.circleInactive {
  width: 16px;
  height: 16px;

  border-radius: 50%;

  background-color: #e6e1df;
}

.bottomSection {
  background: #2d2927;
  height: 1009.847px;

  padding-top: 175px;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: url("/HomeBottomBGImage.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bottomSectionBgImage {
  position: absolute;
  height: 1009.847px;
  width: 100%;

  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
}

.bottomSectionTitle {
  color: #fffff2;
  text-align: center;
  font-size: 60px;
  font-weight: 450;
  line-height: 100%; /* 60px */
  letter-spacing: -1.8px;
}

.BottomButtonsDiv {
  display: flex;
  align-items: center;
  align-items: flex-start;

  gap: 15px;

  margin-top: 290px;

  z-index: 5;
}

.BottomButtonsDivMobile {
  display: none;
}
/* support */
/* 
 */

.supportMain {
  padding: 15px 150px;
  margin-top: 160px;
}

.supportHeader {
  color: #fffff2;
  letter-spacing: -2px;
  font-size: 70px;
  font-weight: 400;
  line-height: 70px;
}

.supportSubTitle {
  color: #fffff2;
  font-size: 19px;
  margin-top: 10px;
  line-height: 120%;
  width: 550px;
  margin-bottom: 47px;
}

.supportLabel {
  color: #ddd;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  display: block;
}

.supportInput {
  display: block;
  height: 46px;
  color: #aaa;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #5c5c5c;
  border-radius: 4px;
  font-size: 16px;
  padding-left: 23px;
  padding-right: 23px;
  width: 550px;
  margin-top: 10px;
}

.supportTextArea {
  min-height: 250px;
  color: #aaa;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #5c5c5c;
  border-radius: 4px;
  padding-left: 23px;
  padding-right: 23px;
  font-size: 16px;
  margin-top: 10px;
  width: 550px;
}

.supportSubmit {
  height: 46px;
  color: #181716;
  background-color: #fffff2;
  border-radius: 4px;
  margin-top: 15px;
  padding-left: 36px;
  padding-right: 36px;
  font-size: 16px;
  display: block;
  border: none;
  margin-bottom: 200px;
}

.supportCheckParent {
  display: flex;
  align-items: center;
}

.supportCheckBoxWrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.supportCheckLabel {
  color: #ddd;
  font-size: 16px;
  font-weight: 400;
  display: block;
  margin-right: 5px;
}

.supportSuccessMessage {
  color: #ddd;
  font-size: 19px;
  margin-top: 50px;
  line-height: 120%;
  width: 550px;
  margin-bottom: 200px;
}

@media only screen and (max-width: 768px) {
  .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s;
    width: 15%;
  }

  .hamburgerMenuParent {
    display: flex;
    flex-direction: column;
    height: 20px;
  }

  .hamburgerMenuChild {
    border-bottom: solid 2px #fffff2;
    height: 20px;
    width: 20px;
  }

  .mobileNavParent {
    display: flex;
    padding: 0;
    height: 100px;
  }

  .mobileLogoParent {
    display: block;
    color: #fffff2;
    overflow: visible;
    width: 85%;
    padding: 5%;
  }

  .nav {
    display: block;
    padding: 0;
    height: 0;
    background-image: none;
    background-color: gray;
    transition: all 1s;
    overflow: hidden;
  }

  .navLeft {
    display: none;
  }

  .navRightParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
    margin-left: 5px;
  }

  .navChild {
    margin: 0;
    padding: 8px 0;
    font-size: 24px;
    width: 100%;
  }

  .navChild a {
    padding: 5%;
  }

  .firstNav {
    border-bottom: 3px #fffff2 solid;
    padding-bottom: 20px;
    border-radius: 3px;
    color: #0082f3;
  }

  .top {
    margin-top: 30px;
    height: 780px;
  }

  .title {
    display: none;
  }

  .titleMobile {
    display: block;

    color: var(--5---Cream-white, #fffff2);
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 450;
    line-height: 110%; /* 44px */
    letter-spacing: -1.2px;

    padding: 0 10px;
  }

  .subtitle {
    font-size: 16px;
    padding: 0 10px;
  }

  .downloadButtonsDiv {
    display: none;
  }

  .downloadButtonsDivMobile {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;

    margin-top: 37px;
  }

  .topPhoneImage {
    bottom: -100px;
    width: 673px;
    height: 563.964px;
  }

  .mid {
    background-image: none;
    height: auto;

    padding-bottom: 80px;
  }

  .midParent {
    flex-direction: column;
    width: 100%;
    margin-top: 140px;
    gap: 40px;
  }

  .midLeft {
    width: 100%;

    padding: 0 12.5px;

    gap: 20px;
  }

  .midLeftTitle {
    font-size: 36px;
    letter-spacing: -1.08px;
  }

  .midLeftSubtitle {
    font-size: 16px;
    letter-spacing: -0.48px;
  }

  .midRight {
    width: 100%;

    align-self: center;

    display: grid;
    grid-template-columns: max-content max-content max-content max-content;
    gap: 15px;
    justify-content: center;
  }

  .midBottom {
    display: none;
  }

  .freakishSection {
    padding-top: 54px;
    padding-bottom: 79px;
  }

  .freakishBackgroundImage {
    display: none;
  }

  .freakishTitle {
    font-size: 36px;
    letter-spacing: -1.08px;
  }

  .freakishSubTitle {
    font-size: 16px;
    letter-spacing: -0.48px;

    padding: 0 10px;
  }

  .freakishImage {
    display: none;
  }

  .freakishImageMobile {
    position: relative;
    width: 120%;
    max-width: 400px;
    height: 299.351px;

    margin-top: 61px;

    display: block;
  }

  .connectSection {
    height: auto;
    padding-top: 63px;
    padding-bottom: 42px;
    flex-direction: column;

    background-image: none;
  }

  .connectWrapper {
    flex-direction: column;
    gap: 54px;
    margin-top: 0;
  }

  .connectLeft {
    width: 100%;
    align-items: center;

    padding: 0 12.5px;
  }

  .bottomSection {
    padding-top: 44px;
    height: 400px;
  }

  .bottomSectionTitle {
    font-size: 36px;
    letter-spacing: -1.08px;

    padding: 0 15px;
  }

  .BottomButtonsDiv {
    display: none;
  }

  .bottomSectionBgImage {
    height: 400px;
  }

  .BottomButtonsDivMobile {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    gap: 10px;

    margin-top: 64px;
    z-index: 5;
  }
  /* support */
  /* 




   */
  .supportMain {
    padding: 15px 15px;
    margin-top: 50px;
  }

  .supportHeader {
    color: #fffff2;
    letter-spacing: -2px;
    font-size: 70px;
    font-weight: 400;
    line-height: 70px;
  }

  .supportSubTitle {
    color: #fffff2;
    font-size: 19px;
    margin-top: 10px;
    line-height: 120%;
    width: 100%;
    margin-bottom: 47px;
  }

  .supportLabel {
    color: #ddd;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    display: block;
  }

  .supportInput {
    display: block;
    height: 46px;
    color: #aaa;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #5c5c5c;
    border-radius: 4px;
    font-size: 16px;
    padding-left: 23px;
    padding-right: 23px;
    width: 100%;
    margin-top: 10px;
  }

  .supportTextArea {
    min-height: 250px;
    color: #aaa;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #5c5c5c;
    border-radius: 4px;
    padding-left: 23px;
    padding-right: 23px;
    font-size: 16px;
    margin-top: 10px;
    width: 100%;
  }

  .supportSubmit {
    height: 46px;
    color: #181716;
    background-color: #fffff2;
    border-radius: 4px;
    margin-top: 15px;
    padding-left: 36px;
    padding-right: 36px;
    font-size: 16px;
    display: block;
    border: none;
    margin-bottom: 200px;
  }

  .supportSuccessMessage {
    width: 100%;
  }
}
